// import logo from './logo.svg';
import './App.css';
import AboutSection from './components/about/about';
import ContactUs from './components/contact/contact';
import AreasOfFocus from './components/focus/focus';
import Header from './components/header/header';
import IntroSection from './components/intro/intro';
import RegistrationForm from './components/registration/form';
import BoardMembers from './components/team/team';
import Footer from './components/footer/footer';

function App() {
  return (
    <div>
      <Header />
      <IntroSection />
      <AboutSection />
      <AreasOfFocus />
      <BoardMembers />
      <ContactUs />
      <RegistrationForm />
      <Footer />
    </div>
  );
}

export default App;
