import { useRef, useState } from "react";
import "./footer.css";

function Footer() {
  const subscriberEmail = useRef();
  const subscriberFormRef = useRef();
  const [message, setMessage] = useState(undefined);
  const [isError, setError] = useState(false);

  const handleSubscribe = function (e) {
    e.preventDefault();

    setError(false);
    setMessage(undefined);

    const email = subscriberEmail.current.value;
    if (email === "" || email === undefined || email === null) {
      setMessage("Please provide a valid email");
      setError(true);
    } else {

      fetch(
        `${process.env.REACT_APP_CLOUD_ENDPOINT}/subscribe`,
        {
          method: "POST",
          body: `{"email": "${email}"}`,
        }
      ).then((response) => {
        response.text().then((data) => {
          try {
            const { state, message } = JSON.parse(data);
            console.log(data, state, message);
            if (state !== "success") {
              setError(true);
            } else {
              subscriberFormRef.current.reset();
            }
            setMessage(message);
          } catch (e) {
            setError(true);
            setMessage("Internal server error. Please try again later.");
          }
        });
      });
    }
  };

  var messageWidget;
  if (message === undefined) {
    messageWidget = <div></div>;
  } else {
    var type = isError ? "danger" : "success";
    messageWidget = (
      <div
        className={`alert alert-dismissible alert-${type} d-flex flex-column flex-sm-row mt-5`}
      >
        <span>{message}</span>
      </div>
    );
  }

  return (
    <footer id="footer" className="footer">
      <div className="footer-newsletter">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-12 text-center">
              <h4>Our Newsletter</h4>
              <p>Stay updated with the latest news from NKRC</p>
            </div>
            <div className="col-lg-6">
              <form ref={subscriberFormRef} onSubmit={handleSubscribe}>
                <div>
                  <input
                    type="email"
                    id="subscriber"
                    name="subscriber"
                    title="subscriber"
                    placeholder="hello@nkrcja.com"
                    ref={subscriberEmail}
                  />
                  <input type="submit" value="Subscribe" />
                </div>
              </form>

              {messageWidget}
            </div>
          </div>
        </div>
      </div>

      <div className="footer-top">
        <div className="container">
          <div className="row gy-4">
            <div className="col-lg-5 col-md-12 footer-info">
              <a href="index.html" className="logo d-flex align-items-center">
                <img src="assets/img/logos/rotaract.png" alt="" />
                <img src="assets/img/logos/logo.png" alt="" />
                <img src="assets/img/logos/service-ninja-solo.png" alt="" />
                {/* <span>NKRC</span>  */}
              </a>
              <p>
                Become one of Rotary’s people of action or explore the many
                opportunities we have for anyone — whatever your age or interest
                — who wants to improve lives in communities near and far.
              </p>
              <div className="social-links mt-3">
                <a href="https://twitter.com/nkrcjamaica" className="twitter">
                  <i className="bi bi-twitter"></i>
                </a>
                <a
                  href="https://www.facebook.com/RotaractClubofNewKingston/"
                  className="facebook"
                >
                  <i className="bi bi-facebook"></i>
                </a>
                <a
                  href="https://www.instagram.com/nkrcjamaica/"
                  className="instagram"
                >
                  <i className="bi bi-instagram bx bxl-instagram"></i>
                </a>
                <a
                  href="https://www.linkedin.com/in/nkrcjamaica/"
                  className="linkedin"
                >
                  <i className="bi bi-linkedin bx bxl-linkedin"></i>
                </a>
              </div>
            </div>

            <div className="col-lg-4 col-6 footer-links">
              <h4>Useful Links</h4>
              <ul>
                <li>
                  <i className="bi bi-chevron-right"></i>{" "}
                  <a href="https://www.rotary.org/en">Rotary.org</a>
                </li>
                <li>
                  <i className="bi bi-chevron-right"></i>{" "}
                  <a href="http://rotaract7020.org/">Rotaract 7020</a>
                </li>
                <li>
                  <i className="bi bi-chevron-right"></i>{" "}
                  <a href="https://7020.org/">Rotary 7020</a>
                </li>
                <li>
                  <i className="bi bi-chevron-right"></i>{" "}
                  <a href="http://rotaract7020.org/district-conference/">
                    District Conference
                  </a>
                </li>
                <li>
                  <i className="bi bi-chevron-right"></i> <a href="https://my-cms.rotary.org/en/document/rotaract-handbook">Rotaract Handbook</a>
                </li>
              </ul>
            </div>

            <div className="col-lg-3 col-md-12 footer-contact text-center text-md-start">
              <h4>Contact Us</h4>
              <p>
                c/o Rotaract Club of New Kingston
                <br />
                The Liguanea Club
                <br />
                5 Knutsford Blvd, Kingston
                <br />
                Kingston, Jamaica
                <br />
                <br />
                <strong>Email: newkingstonrotaract@gmail.com</strong>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="copyright">
          &copy; Copyright{" "}
          <strong>
            <span>Rotaract Club of New Kingston</span>
          </strong>
          . All Rights Reserved
        </div>
        <div className="credits">
          {/* <!-- All the links in the footer should remain intact. -->
        <!-- You can delete the links only if you purchased the pro version. -->
        <!-- Licensing information: https://bootstrapmade.com/license/ -->
        <!-- Purchase the pro version with working PHP/AJAX contact form: https://bootstrapmade.com/flexstart-bootstrap-startup-template/ --> */}
          Designed by <a href="https://bootstrapmade.com/">BootstrapMade</a>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
