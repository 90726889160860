import "./intro.css";

function IntroSection() {
  return (
    // <div>This is the hero</div>
    <section id="hero" className="hero d-flex align-items-center">
      <div className="container">
        <div className="row">
          <div className="col-lg-6 d-flex flex-column justify-content-center">
            <h1>People of Action</h1>
            <h2>
              Join the global movement of young leaders who are developing
              innovative solutions to the world’s most pressing challenges.
            </h2>

            <div className="mb-5">
              <div className="text-center text-lg-start">
                <a
                  href="#registration"
                  className="btn-get-started scrollto d-inline-flex align-items-center justify-content-center align-self-center"
                >
                  <span>Get Involved</span>
                  <i className="bi bi-arrow-right"></i>
                </a>
              </div>
            </div>

            <p className="mt-5">
              We meet every 1st and 3rd Thursday of each month at The Liguanea Club in New Kingston at 6:30 p.m.
            </p>
          </div>
          <div className="col-lg-6 hero-img">
            <img src="assets/img/hero-img-2.jpeg" className="img-fluid" alt="" />
          </div>
        </div>
      </div>
    </section>
  );
}

export default IntroSection;
