import "./header.css";
import {useState, useEffect } from "react";

function Header() {
  const select = (el, all = false) => {
    if (el !== undefined) {
      el = el?.trim();
      if (all) {
        return [...document.querySelectorAll(el)];
      } else {
        return document.querySelector(el);
      }
    }
  };

  const onscroll = (el, listener) => {
    el.addEventListener("scroll", listener);
  };

  let navbarlinks = select("#navbar .scrollto", true);
  const navbarlinksActive = () => {
    let position = window.scrollY + 200;
    navbarlinks.forEach((navbarlink) => {
      if (!navbarlink.hash) return;
      let section = select(navbarlink.hash);
      if (!section) return;
      if (
        position >= section.offsetTop &&
        position <= section.offsetTop + section.offsetHeight
      ) {
        navbarlink.classList.add("active");
      } else {
        navbarlink.classList.remove("active");
      }
    });
  };
  window.addEventListener("load", navbarlinksActive);
  onscroll(document, navbarlinksActive);

  /**
   * Scrolls to an element with header offset
   */
  const scrollto = (el) => {
    let header = select("#header");
    let offset = header.offsetHeight;

    if (!header.classList.contains("header-scrolled")) {
      offset -= 10;
    }
    let elementPos = select(el).offsetTop;
    window.scrollTo({
      top: elementPos - offset,
      behavior: "smooth",
    });
  };

  /**
   * Toggle .header-scrolled class to #header when page is scrolled
   */
  const [headerScrolled, setHeaderScrolled] = useState(false);
  useEffect((_) => {
    const handleScroll = (_) => {
      // sets the backgroun of the header on scroll
      if (window.scrollY > 100 || window.pageYOffset > 100) {
        setHeaderScrolled(true);
      } else {
        setHeaderScrolled(false);
      }
    };
    const windowLoaded = () => {
      // Once the window loads it will check if there's 
      // a hash then scroll to that position on the page
      if (window.location.hash) {
        if (select(window.location.hash)) {
          scrollto(window.location.hash);
        }
      }
    }
    window.addEventListener("scroll", handleScroll);
    window.addEventListener("load", windowLoaded);
    return (_) => {
      window.removeEventListener("scroll", handleScroll);
      window.removeEventListener("load", windowLoaded);
    };
  });

  const navBarMenuClicked = function (e) {
    select("#navbar").classList.toggle("navbar-mobile");
    e.target.classList.toggle("bi-list");
    e.target.classList.toggle("bi-x");
  };

  const onMenuItemClicked = function (e) {
    if (select(e.target.hash)) {
      e.preventDefault();
      let navbar = select("#navbar");
      if (navbar.classList.contains("navbar-mobile")) {
        navbar.classList.remove("navbar-mobile");
        let navbarToggle = select(".mobile-nav-toggle");
        navbarToggle.classList.toggle("bi-list");
        navbarToggle.classList.toggle("bi-x");
      }
      scrollto(e.target.hash);
    }
  };

  return (
    <header
      id="header"
      className={`header fixed-top ${headerScrolled ? "header-scrolled" : ""}`}
    >
      <div className="container-fluid container-xl d-flex align-items-center justify-content-between">
        <a href="/" className="logo d-flex align-items-center">
          <img src="assets/img/logos/rotaract.png" alt="NKRC logo" />
          {/* <span>NKRC</span> */}
        </a>

        <nav id="navbar" className="navbar">
          <ul>
            <li>
              <a
                className="nav-link scrollto active"
                href="#hero"
                onClick={onMenuItemClicked}
              >
                Home
              </a>
            </li>
            <li>
              <a
                className="nav-link scrollto"
                href="#about"
                onClick={onMenuItemClicked}
              >
                About
              </a>
            </li>
            <li>
              <a
                className="nav-link scrollto"
                href="#services"
                onClick={onMenuItemClicked}
              >
                Focus
              </a>
            </li>
            <li>
              <a
                className="nav-link scrollto"
                href="#team"
                onClick={onMenuItemClicked}
              >
                Team
              </a>
            </li>
            <li>
              <a
                className="nav-link scrollto"
                href="#contact"
                onClick={onMenuItemClicked}
              >
                Contact
              </a>
            </li>
            {/*
            <li>
              <a
                className="nav-link scrollto"
                href="#registration"
                onClick={onMenuItemClicked}
              >
                Membership
              </a>
            </li>
            */}
            <li>
              <a target="_blank" rel="noreferrer" href="http://rotaract7020.org/">District 7020</a>
            </li>
          </ul>
          <i
            className="bi bi-list mobile-nav-toggle"
            onClick={navBarMenuClicked}
          ></i>
        </nav>
      </div>
    </header>
  );
}

export default Header;
