export default function RegistrationForm() {
  return (
    <div id="registration">
      {/* <div
        data-tf-widget="ZlidTdzW"
        data-tf-iframe-props="title=Registration Form"
        data-tf-medium="snippet"
        style={{"width":"100%", "height":"100vh"}}
      ></div> */}
    </div>
  );
}
