import "./contact.css";
import { useRef, useState } from "react";

/*

*/

function ContactUs() {
  const name = useRef();
  const email = useRef();
  const subject = useRef();
  const messageBody = useRef();
  const contactForm = useRef();

  const [message, setMessage] = useState(undefined);
  const [isError, setError] = useState(false);

  const sendMessage = function (e) {
    e.preventDefault();
    setError(false);
    setMessage(undefined);

    const messageData = {
      email: email.current.value,
      name: name.current.value,
      subject: subject.current.value,
      message: messageBody.current.value,
    };

    if (_validate(messageData)) {
      const payload = JSON.stringify(messageData);
      fetch(`${process.env.REACT_APP_CLOUD_ENDPOINT}/contact`, {
        method: "POST",
        body: payload,
      }).then((response) => {
        response.text().then((data) => {
          try {
            const { state, message } = JSON.parse(data);
            console.log(data, state, message);
            if (state !== "success") {
              setError(true);
            } else {
              contactForm.current.reset();
            }
            setMessage(message);
          } catch (e) {
            setError(true);
            setMessage("Internal server error. Please try again later.");
          }
        });
      });
    } else {
      sendMessage("Kindly fill out all required fields");
      setError(true);
    }
  };

  const _validate = function (data) {
    if (
      data.email === undefined ||
      data.name === undefined ||
      data.subject === undefined ||
      data.message === undefined
    ) {
      return false;
    }
    if (
      data.email === null ||
      data.name === null ||
      data.subject === null ||
      data.message === null
    ) {
      return false;
    }
    return true;
  };

  var messageWidget;
  if (message === undefined) {
    messageWidget = <div></div>;
  } else {
    var type = isError ? "danger" : "success";
    messageWidget = (
      <div
        className={`alert alert-dismissible alert-${type} d-flex flex-column flex-sm-row mt-5`}
      >
        <span>{message}</span>
      </div>
    );
  }

  return (
    <section id="contact" className="contact">
      <div className="container">
        <header className="section-header">
          <h2>Contact</h2>
          <p>Contact Us</p>
        </header>

        <div className="row gy-4">
          <div className="col-lg-6">
            <div className="row gy-4">
              <div className="col-md-6">
                <div className="info-box">
                  <i className="bi bi-geo-alt"></i>
                  <h3>Address</h3>
                  <p>
                    c/o Rotaract Club of New Kingston
                    <br />
                    The Liguanea Club
                    <br />
                    5 Knutsford Blvd, Kingston
                    <br />
                    Kingston, Jamaica
                  </p>
                </div>
              </div>
              <div className="col-md-6">
                <div className="info-box">
                  <i className="bi bi-envelope"></i>
                  <h3>Email Us</h3>
                  <p>
                    newkingstonrotaract@gmail.com
                    <br />
                    pr.nkrc@gmail.com
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-6">
            <form
              ref={contactForm}
              onSubmit={sendMessage}
              className="php-email-form"
            >
              <div className="row gy-4">
                <div className="col-md-6">
                  <input
                    type="text"
                    name="name"
                    className="form-control"
                    placeholder="Your Name"
                    required
                    ref={name}
                  />
                </div>

                <div className="col-md-6 ">
                  <input
                    type="email"
                    className="form-control"
                    name="email"
                    placeholder="Your Email"
                    required
                    ref={email}
                  />
                </div>

                <div className="col-md-12">
                  <input
                    type="text"
                    className="form-control"
                    name="subject"
                    placeholder="Subject"
                    required
                    ref={subject}
                  />
                </div>

                <div className="col-md-12">
                  <textarea
                    ref={messageBody}
                    className="form-control"
                    name="message"
                    rows="6"
                    placeholder="Message"
                    required
                  ></textarea>
                </div>

                <div className="col-md-12 text-center">
                  <div className="loading">Loading</div>
                  <div className="error-message"></div>
                  <div className="sent-message">
                    Your message has been sent. Thank you!
                  </div>

                  <button type="submit">Send Message</button>
                </div>
              </div>
              {messageWidget}
            </form>
          </div>
        </div>
      </div>
    </section>
  );
}

export default ContactUs;
