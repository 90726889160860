import "./about.css";

function AboutSection() {
  return (
    <section id="about" className="about">
      <div className="container">
        <div className="row gx-0">
          <div className="col-lg-6 d-flex flex-column justify-content-center">
            <div className="content">
              <h3>Who We Are</h3>
              <h2>Rotaract Club of New Kingston</h2>
              <p>
                The Rotaract Club of New Kingston (NKRC) is a non-profit
                organization that was officially chartered on April 7 in 2004, which was
                signed off by the then President of the Rotary Club of New
                Kingston Dr. Garth Alexander. However, we celebrate our charter
                day on April 1, 2004 as that was the day the first board was
                installed. The club comprises of young professionals over the
                age of 18 who are passionate about service and giving back to
                society and does so in numerous ways.
                {/* We meet every 1st and 3rd
                Thursday of each month at The Liguanea Club in New Kingston at
                6:30 p.m. */}
              </p>
              <p>
                The core principle of Rotary is "Service Above Self," which
                calls on Rotaractors and Rotarians to put service to their
                communities above all else. Through this, the core principle of
                "Fellowship Through Service" was formed where Rotaract clubs
                would focus on building friendships among their club members
                while they endeavour to serve their community. The 4-way test
                covers both principles, challenging both Rotaractors and
                Rotarians to live by them throughout their everyday lives.
              </p>
              <h2>
                Your time, energy, and a passion to improve your world are all
                you need to get involved with Rotary.
              </h2>
              <div className="mt-5 text-center text-lg-start">
                <a
                  href="https://www.rotary.org/en/get-involved/rotaract-clubs#page-content/"
                  className="btn-read-more d-inline-flex align-items-center justify-content-center align-self-center"
                  target="_blank"
                  rel="noreferrer"
                >
                  <span>Read More</span>
                  <i className="bi bi-arrow-right"></i>
                </a>
              </div>
            </div>
          </div>

          <div className="col-lg-6 d-flex align-items-center">
            <img src="assets/img/about-img.jpeg" className="img-fluid" alt="" />
          </div>
        </div>
      </div>
      <div className="counts" id="counts">
        <div className="container" data-aos="fade-up">
          <div className="row gy-4">
            <div className="col-lg-4 col-md-6">
              <div className="count-box justify-content-center">
                <i className="ri-service-fill"></i>
                <div>
                  <span
                  >5000+</span>
                  <p>Lives Impacted</p>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-6">
              <div className="count-box justify-content-center">
                <i
                  className="bi bi-journal-richtext"
                  style={{ color: "#ee6c20" }}
                ></i>
                <div>
                  <span>100+</span>
                  <p>Projects Completed</p>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-6">
              <div className="count-box justify-content-center">
                <i className="bi bi-headset" style={{ color: "#15be56" }}></i>
                <div>
                  <span>100+</span>
                  <p>Partnerships</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default AboutSection;
