import "./focus.css";

export default function AreasOfFocus() {
  return (
    <section id="services" className="services">
      <div className="container">
        <header className="section-header">
          <h2>Rotary Areas of Focus</h2>
          <p>
            Rotary is dedicated to causes that build international
            relationships, improve lives, and create a better world to support
            our peace efforts and end polio forever.
          </p>
        </header>

        <div className="row gy-4">
          <div className="col-lg-3  col-md-6">
            <div className="service-box blue">
              <i className="ri-virus-line icon"></i>
              <h3>Fighting Disease</h3>
            </div>
          </div>

          <div className="col-lg-3 col-md-6">
            <div className="service-box orange">
              <i className="bi bi-peace icon bs-icon"></i>
              <h3>Promoting Peace</h3>
            </div>
          </div>

          <div className="col-lg-3 col-md-6">
            <div className="service-box green">
              <i className="ri-contrast-drop-2-line icon"></i>
              <h3>Providing clean water, sanitation, and hygiene</h3>
            </div>
          </div>

          <div className="col-lg-3 col-md-6">
            <div className="service-box red">
              <i className="ri-hand-heart-line icon"></i>
              <h3>Saving mothers and children</h3>
            </div>
          </div>

          <div className="col-lg-3 col-md-6">
            <div className="service-box purple">
              <i className="ri-book-open-line icon"></i>
              <h3>Supporting education</h3>
            </div>
          </div>

          <div className="col-lg-3 col-md-6">
            <div className="service-box pink">
              <i className="ri-bank-line icon"></i>
              <h3>Growing local economies</h3>
            </div>
          </div>
          <div className="col-lg-3 col-md-6">
            <div className="service-box dark-blue">
              <i className="bi bi-tree bs-icon icon"></i>
              <h3>Protecting the environment</h3>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
