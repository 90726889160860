import './team.css'

function BoardMembers() {
  return (
    <section id="team" className="team">

      <div className="container" >

        <header className="section-header">
          <h2>NKRC Team</h2>
          <p>Meet the Executives</p>
        </header>

        <div className="row gy-4 d-flex justify-content-center">

          <div className="col-lg-3 col-md-6 d-flex align-items-stretch">
            <div className="member">
              <div className="member-img">
                <img src="assets/img/team/president.jpeg" className="img-fluid" alt=""/>
              </div>
              <div className="member-info">
                <h4>Shanice Salmon</h4>
                <span>President</span>
              </div>
            </div>
          </div>

          <div className="col-lg-3 col-md-6 d-flex align-items-stretch">
            <div className="member">
              <div className="member-img">
                <img src="assets/img/team/ipp.jpeg" className="img-fluid" alt=""/>
              </div>
              <div className="member-info">
                <h4>Anishka Smith</h4>
                <span>Immediate Past President</span>
              </div>
            </div>
          </div>

          <div className="col-lg-3 col-md-6 d-flex align-items-stretch">
            <div className="member">
              <div className="member-img">
                <img src="assets/img/team/vp.jpeg" className="img-fluid" alt=""/>
              </div>
              <div className="member-info">
                <h4>Horace Collins</h4>
                <span>Vice President</span>
              </div>
            </div>
          </div>

          <div className="col-lg-3 col-md-6 d-flex align-items-stretch">
            <div className="member">
              <div className="member-img">
                <img src="assets/img/team/secretary.jpeg" className="img-fluid" alt=""/>
              </div>
              <div className="member-info">
                <h4>Deidre-Ann Burrell</h4>
                <span>Secretary</span>
              </div>
            </div>
          </div>

          <div className="col-lg-3 col-md-6 d-flex align-items-stretch">
            <div className="member">
              <div className="member-img">
                <img src="assets/img/team/treasurer.jpeg" className="img-fluid" alt=""/>
              </div>
              <div className="member-info">
                <h4>Okeeno McLeod</h4>
                <span>Treasurer</span>
              </div>
            </div>
          </div>

          <div className="col-lg-3 col-md-6 d-flex align-items-stretch">
            <div className="member">
              <div className="member-img">
                <img src="assets/img/team/saa.jpeg" className="img-fluid" alt=""/>
              </div>
              <div className="member-info">
                <h4>Nathaylia Wilberforce</h4>
                <span>Sergeant at Arms</span>
              </div>
            </div>
          </div>

          

        </div>

      </div>

    </section>
  )
}

export default BoardMembers
